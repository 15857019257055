.input_container {
  position: relative;
  height: 55px;
  background-color: var(--main-bg);
  display: flex;
  margin-right: 2rem;
  margin-bottom: 2rem;
  align-items: center;
  width: 22rem;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
}
.input_containertext {
  position: relative;
  height: 120px;
  background-color: var(--main-bg);
  display: flex;
  margin-right: 2rem;
  margin-bottom: 2rem;
  align-items: center;
  width: 22rem;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
}
.input_container > input {
  height: 100%;
  width: 100%;
  padding: 10px 60px 10px 20px;
  font-size: 1rem;
  border-radius: var(--border-radius);
  color: var(--txt-color);
  background-color: var(--main-bg);
}

.input_containertext > textarea {
  height: 100%;
  width: 100%;
  padding: 10px 20px 10px 20px;
  font-size: 1rem;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: var(--border-radius);
  color: var(--txt-color);
  background-color: var(--main-bg);
}

.input_container > i {
  font-size: 1.5rem;
  position: absolute;
  right: 20px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
