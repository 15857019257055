@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html {
  -webkit-print-color-adjust: exact;
}
:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --main-color: #86bfbf;
  --second-color: #93baba;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-color-blue: #86bfbf;
  --second-color-blue: #93baba;

  --main-color-red: #fb0b12;
  --second-color-red: #ff4a6b;

  --main-color-cyan: #10d4d2;
  --second-color-cyan: #2ae9e6;

  --main-color-green: #019707;
  --second-color-green: #4caf50;

  --main-color-orange: #d68102;
  --second-color-orange: #fca11a;

  --sidebar-width: 300px;
  --border-radius: 15px;
  --topnav-height: 110px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: var(--main-color);
}

button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: var(--txt-color);
}

ul {
  list-style-type: none;
}

input {
  border: 2px solid transparent;
  outline: 0;
}

input:focus {
  border: 2px solid var(--main-color);
}

.page-header {
  margin-bottom: 40px;
  text-transform: capitalize;
}

.card {
  padding: 30px;
  margin-bottom: 30px;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.full-height {
  height: 100%;
}

.card.full-height {
  height: calc(100% - 30px);
}

.card__header {
  text-transform: capitalize;
}

.card > div ~ div {
  margin-top: 30px;
}

.card__footer {
  text-align: center;
  text-transform: capitalize;
}

.light-background {
  background-color: var(--main-bg-light);
  color: #000;
}

.dark-background {
  background-color: var(--main-bg-dark);
  color: #fff;
}

.blue-color {
  background-color: var(--main-color-blue);
  color: #fff;
}

.red-color {
  background-color: var(--main-color-red);
  color: #fff;
}

.cyan-color {
  background-color: var(--main-color-cyan);
  color: #fff;
}

.green-color {
  background-color: var(--main-color-green);
  color: #fff;
}

.orange-color {
  background-color: var(--main-color-orange);
  color: #fff;
}
.add_button {
  background-color: #9cd5d5;
  cursor: pointer;
  padding: 1rem;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  width: 10rem;
  display: flex;
  margin-right: 14rem;
  align-items: center;
  height: 50px;
}
.add_button:hover {
  background-color: #77a5a5;
  cursor: pointer;
  padding: 1rem;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  width: 10rem;
  display: flex;
  margin-right: 14rem;
  align-items: center;
  height: 50px;
}
.add_button p {
  color: var(--txt-color-light);
}

.remove_button p {
  color: var(--txt-color-dark);
}
.remove_button {
  background-color: #e75353;
  cursor: pointer;
  padding: 1rem;
  text-align: center;
  justify-content: center;
  /* border-radius: 5px; */
  width: 6rem;
  display: flex;
  margin-right: 14rem;
  color: #fff;
  align-items: center;
  height: 40px;
}

.add_button i {
  color: var(--txt-color-light);
  margin-left: 0.5rem;
  font-size: 1.4rem;
}
.remove_button i {
  color: var(--txt-color-dark);
  margin-left: 0.5rem;
  font-size: 1.4rem;
}

.delete_button {
  background-color: #9cd5d5;
  cursor: pointer;
  padding: 1rem;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  width: 10rem;
  display: flex;
  /* margin-right: 14rem; */
  align-items: center;
  height: 50px;
  margin-left: -5rem;
}

.delete_button p {
  color: var(--txt-color-light);
}

.delete_button i {
  color: var(--txt-color-light);
  margin-left: 0.5rem;
  font-size: 1.4rem;
}
.uploadcare--widget__button_type_open {
  background-color: #9cd5d5;
  cursor: pointer;
  padding: 1rem;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  width: 10rem;
  display: flex;
  margin-right: 14rem;
  color: var(--txt-color);
  align-items: center;
  height: 50px;
}

.uploadcare--widget__button_type_open:hover {
  background-color: #5e8686;
  cursor: pointer;
  padding: 1rem;
  text-align: center;
  justify-content: center;
  border-radius: 5px;
  width: 10rem;
  display: flex;
  margin-right: 14rem;
  color: var(--txt-color);
  align-items: center;
  height: 50px;
}
.uploadcare--progress_type_canvas {
  color: #9cd5d5;
}

.form-control {
  padding: 0.4rem 0.7rem;
  border-radius: 0.2rem;
  background-color: transparent;
  border: 1px solid #fff;
  position: relative;
  color: var(--txt-color);
  font-size: 1rem;

  height: 55px;
  background-color: var(--main-bg);
  display: flex;
  margin-right: 2rem;
  margin-bottom: 2rem;
  align-items: center;
  width: 22rem;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.video-responsive {
  overflow: hidden;
  margin-top: 5%;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

span.cls_003 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
div.cls_003 {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_004 {
  font-family: "Roboto", sans-serif;
  font-size: 15.1px;
  color: rgb(63, 63, 63);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_004 {
  font-family: "Roboto", sans-serif;
  font-size: 15.1px;
  color: rgb(63, 63, 63);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_005 {
  font-family: "Roboto", sans-serif;
  font-size: 17.1px;
  color: rgb(63, 63, 63);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
div.cls_005 {
  font-family: "Roboto", sans-serif;
  font-size: 17.1px;
  color: rgb(63, 63, 63);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_006 {
  font-family: "Roboto", sans-serif;
  font-size: 11.1px;
  color: rgb(63, 63, 63);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_006 {
  font-family: "Roboto", sans-serif;
  font-size: 11.1px;
  color: rgb(63, 63, 63);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_019 {
  font-family: "Roboto", sans-serif;
  font-size: 11.1px;
  color: rgb(63, 63, 63);
  font-weight: normal;
  font-style: normal;
  text-decoration: underline;
}
div.cls_019 {
  font-family: "Roboto", sans-serif;
  font-size: 11.1px;
  color: rgb(63, 63, 63);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_007 {
  font-family: "Roboto", sans-serif;
  font-size: 12.1px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
div.cls_007 {
  font-family: "Roboto", sans-serif;
  font-size: 12.1px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_010 {
  font-family: "Roboto", sans-serif;
  font-size: 14.1px;
  color: rgb(255, 254, 255);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
div.cls_010 {
  font-family: "Roboto", sans-serif;
  font-size: 14.1px;
  color: rgb(255, 254, 255);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_008 {
  font-family: "Roboto", sans-serif;
  font-size: 14.1px;
  color: rgb(255, 254, 255);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_008 {
  font-family: "Roboto", sans-serif;
  font-size: 14.1px;
  color: rgb(255, 254, 255);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_009 {
  font-family: "Roboto", sans-serif;
  font-size: 10.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_009 {
  font-family: "Roboto", sans-serif;
  font-size: 10.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_011 {
  font-family: "Roboto", sans-serif;
  font-size: 20.1px;
  color: rgb(255, 254, 255);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
div.cls_011 {
  font-family: "Roboto", sans-serif;
  font-size: 20.1px;
  color: rgb(255, 254, 255);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_012 {
  font-family: "Roboto", sans-serif;
  font-size: 11.1px;
  color: rgb(63, 63, 63);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
div.cls_012 {
  font-family: "Roboto", sans-serif;
  font-size: 11.1px;
  color: rgb(63, 63, 63);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_013 {
  font-family: "Roboto", sans-serif;
  font-size: 10.1px;
  color: rgb(120, 128, 129);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
div.cls_013 {
  font-family: "Roboto", sans-serif;
  font-size: 10.1px;
  color: rgb(120, 128, 129);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_017 {
  font-family: "Roboto", sans-serif;
  font-size: 10.1px;
  color: rgb(120, 128, 129);
  font-weight: bold;
  font-style: normal;
  text-decoration: underline;
}
div.cls_017 {
  font-family: "Roboto", sans-serif;
  font-size: 10.1px;
  color: rgb(120, 128, 129);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_014 {
  font-family: "Roboto", sans-serif;
  font-size: 10.1px;
  color: rgb(120, 128, 129);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
div.cls_014 {
  font-family: "Roboto", sans-serif;
  font-size: 10.1px;
  color: rgb(120, 128, 129);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_018 {
  font-family: "Roboto", sans-serif;
  font-size: 10.1px;
  color: rgb(120, 128, 129);
  font-weight: bold;
  font-style: normal;
  text-decoration: underline;
}
div.cls_018 {
  font-family: "Roboto", sans-serif;
  font-size: 10.1px;
  color: rgb(120, 128, 129);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
span.cls_015 {
  font-family: "Roboto", sans-serif;
  font-size: 12.1px;
  color: rgb(255, 254, 255);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_015 {
  font-family: "Roboto", sans-serif;
  font-size: 12.1px;
  color: rgb(255, 254, 255);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_016 {
  font-family: "Roboto", sans-serif;
  font-size: 12.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_016 {
  font-family: "Roboto", sans-serif;
  font-size: 12.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_020 {
  font-family: "Roboto", sans-serif;
  font-size: 12.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: underline;
}
div.cls_020 {
  font-family: "Roboto", sans-serif;
  font-size: 12.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.download-table-xls-button {
  background: #90bbbb;
  border: none;
  color: #fff;
  padding: 7px 14px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  margin-left: 20px;
}
