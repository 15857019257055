.modal-img {
  width: 150px;
  height: 150px;
  display: block;
  margin: auto;
  margin-bottom: 1rem;
  border-radius: 50rem;
  margin-top: -5rem;
}

.modal-input {
  width: 100%;
  color: #fff;
  padding: 12px 12px;
  font-size: 1rem;
  margin: 8px 0;
  color: var(--txt-color);
  display: inline-block;
  border: 0.1px solid rgba(134, 134, 134, 0.651);
  background-color: transparent;
  border-radius: 4px;
  box-sizing: border-box;
}
.model-select {
  width: 100%;
  color: #fff;
  padding: 12px 12px;
  font-size: 1rem;
  margin: 8px 0;
  color: var(--txt-color);
  display: inline-block;
  border: 0.1px solid rgba(134, 134, 134, 0.651);
  background-color: transparent;
  border-radius: 4px;
  box-sizing: border-box;
}
.buttonContainer {
  display: flex;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.center:hover {
  margin-left: auto;
  margin-right: auto;
}
