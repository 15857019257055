/* .tableFooter {
  background-color: #f1f1f1;
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  border: none;
  padding: 7px 14px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

.activeButton {
  color: white;
  background: #185adb;
}

.inactiveButton {
  color: #2c3e50;
  background: #f9f9f9;
} */

.tableFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.button ~ .button {
  margin-left: 10px;
}

.button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.activeButton,
.activeButton:hover {
  background-color: var(--main-color);
  color: var(--txt-white);
  font-weight: 600;
}

.activeButton:hover {
  color: var(--txt-white);
  background-color: var(--second-color);
}
